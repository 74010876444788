import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { api } from "../../../utils/api";
registerLocale("ru", ru);

export default function Weekend({
  setIsEditModalOpen,
  isEditModalOpen,
  spdayId,
  setSpdayId,
}) {
  const [form, setForm] = useState({
    date: "",
    name: "",
    status: "weekend",
    date_f: new Date(),
    date_t: new Date(),
  });
  const [typeOfCreating, setTypeOfCreating] = useState(1);

  useEffect(() => {
    if (!spdayId) return;
    setTypeOfCreating(1);
    api
      .getSPDay(spdayId)
      .then(({ spday }) => setForm(spday))
      .catch((err) => console.log(err));
  }, [spdayId]);

  const onClose = () => {
    setSpdayId(null);
    setIsEditModalOpen(false);
    setTypeOfCreating(1);
    setForm({
      date: "",
      name: "",
      status: "weekend",
      date_f: new Date(),
      date_t: new Date(),
    });
  };

  const onSubmit = () => {
    if (form._id) {
      const { _id, date, name, status } = form;
      api
        .updateSPDay({ _id, date, name, status })
        .then((res) => onClose())
        .catch((err) => console.log(err));
    } else {
      const { date, name, status, date_f, date_t } = form;
      api
        .createSPDay({
          date: typeOfCreating === 1 ? date : undefined,
          name,
          status,
          date_f: typeOfCreating === 2 ? date_f : undefined,
          date_t: typeOfCreating === 2 ? date_t : undefined,
        })
        .then((res) => onClose())
        .catch((err) => console.log(err));
    }
  };

  const onDelete = () => {
    api
      .deleteSPDay({ _id: form._id })
      .then((res) => onClose())
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={isEditModalOpen} onHide={() => onClose()}>
      <Modal.Header closeButton>Спец. день</Modal.Header>
      <Modal.Body>
        {!form._id ? (
          <ButtonGroup className="mb-3">
            <Button variant="outline" onClick={() => setTypeOfCreating(1)}>
              Один день
            </Button>
            <Button variant="outline" onClick={() => setTypeOfCreating(2)}>
              Период
            </Button>
          </ButtonGroup>
        ) : (
          ""
        )}
        {typeOfCreating === 1 ? (
          <Form.Group className="mb-3">
            <Form.Label>Дата</Form.Label>
            <DatePicker
              selected={form.date ? new Date(form.date) : new Date()}
              className="form-control"
              locale="ru"
              dateFormat="dd.MM.yyyy"
              onChange={(date) => setForm({ ...form, date })}
            />
          </Form.Group>
        ) : (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Дата начала периода</Form.Label>
              <DatePicker
                selected={form.date_f}
                className="form-control"
                locale="ru"
                dateFormat="dd.MM.yyyy"
                onChange={(date_f) => setForm({ ...form, date_f })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Дата конца периода</Form.Label>
              <DatePicker
                selected={form.date_t}
                className="form-control"
                locale="ru"
                dateFormat="dd.MM.yyyy"
                onChange={(date_t) => {
                  setForm({ ...form, date_t });
                }}
              />
            </Form.Group>
          </>
        )}

        <Form.Group className="mb-3">
          <Form.Label>Название</Form.Label>
          <Form.Control
            type="text"
            placeholder="Введите название"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            name="name"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Статус</Form.Label>
          <Form.Select
            value={form.status}
            onChange={(e) => setForm({ ...form, status: e.target.value })}
          >
            <option value="weekend">Выходной день</option>
            <option value="working">Рабочий день</option>
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {form._id ? (
          <Button variant="danger" onClick={() => onDelete()}>
            Удалить
          </Button>
        ) : (
          ""
        )}
        <Button onClick={() => onSubmit()}>Сохранить</Button>
      </Modal.Footer>
    </Modal>
  );
}
