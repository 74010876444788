import React, { useState, useEffect, useContext } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../utils/api";
import AllOrderPopup from "./AllOrderPopup";
import { UserContext } from "../utils/UserContext";

function getMonday(date) {
  const d = new Date(new Date(date).setHours(0, 0, 0, 0));
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
}

function getSunday(date) {
  const d = new Date(new Date(date).setHours(0, 0, 0, 0));
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1) + 6;
  return new Date(d.setDate(diff));
}

const AllOrders = () => {
  const [data, setData] = useState(null);
  const [week, setWeek] = useState([]);
  const [foundActiveWeek, setFoundActiveWeek] = useState(false);
  const [dateFrom, setDateFrom] = useState(getMonday(new Date()));
  const [dateTo, setDateTo] = useState(getSunday(new Date()));
  const [ordersByWeekDays, setOrdersByWeekDays] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);
  const [activeOrderWarning, setActiveOrderWarning] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const userContext = useContext(UserContext);

  const navigator = useNavigate();

  useEffect(() => {
    if (!needUpdate || isFirstLoading) {
      setIsFirstLoading(false);
      return;
    }
    setIsLoading(true);

    Promise.all([
      api.getOrders({ f_datef: dateFrom, f_datet: dateTo, p: 1, s: 1000 }),
      api.getAllWeek({ date: dateFrom }),
    ])
      .then(([{ list }, { result }]) => {
        setData(list);
        setWeek(result);
      })
      .catch((err) => err.status === 403 && navigator("/access_denied"))
      .finally(() => {
        setNeedUpdate(false);
        setIsLoading(false);
      });
  }, [dateFrom, dateTo, needUpdate, isFirstLoading]);

  useEffect(() => {
    if (
      !week.length ||
      foundActiveWeek ||
      dateFrom.getMilliseconds() !== new Date(week[0]?.date).getMilliseconds()
    )
      return;
    if (new Date(week.filter((el) => !el.is_work)[0].date) < new Date()) {
      changeWeek("+");
    } else {
      setFoundActiveWeek(true);
    }
  }, [week]);

  const changeWeek = (whereTo) => {
    if (whereTo === "+") {
      const lastWeekDay = getSunday(new Date(dateFrom));
      const firstDayNextWeek = new Date(
        new Date(lastWeekDay).setDate(lastWeekDay.getDate() + 1)
      );
      setDateFrom(getMonday(firstDayNextWeek));
      setDateTo(getSunday(firstDayNextWeek));
    } else if (whereTo === "-") {
      const lastWeekDay = getMonday(new Date(dateFrom));
      const lastDayPrevWeek = new Date(
        new Date(lastWeekDay).setDate(lastWeekDay.getDate() - 1)
      );
      setDateFrom(getMonday(lastDayPrevWeek));
      setDateTo(getSunday(lastDayPrevWeek));
    }
    setNeedUpdate(true);
  };
  return (
    <>
      <h1>Вся еда</h1>
      <div className="d-flex align-items-center justify-content-center mb-3">
        <i
          className="bi bi-chevron-left me-2 fs-3"
          onClick={() => changeWeek("-")}
          style={{ cursor: "pointer" }}
        />
        {dateFrom.toLocaleDateString("ru-RU", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}{" "}
        —{" "}
        {dateTo.toLocaleDateString("ru-RU", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
        <i
          className="bi bi-chevron-right fs-3 ms-2"
          onClick={() => changeWeek("+")}
          style={{ cursor: "pointer" }}
        />
      </div>
      {data ? (
        <div style={{ overflow: "scroll", maxWidth: "98vw" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Имя</th>
                {data[0].orders.map((el, ind) => (
                  <th
                    className={`text-center${
                      !week[ind].is_work ? " text-danger" : ""
                    }`}
                  >
                    {el.weekDay},{" "}
                    {new Date(el.date).toLocaleDateString("ru-RU", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })}
                    {week[ind].spday ? (
                      <p className="m-0">{week[ind].spday.name}</p>
                    ) : (
                      ""
                    )}
                  </th>
                ))}
                {/* <th className="text-center">Понедельник</th>
                <th className="text-center">Вторник</th>
                <th className="text-center">Среда</th>
                <th className="text-center">Четверг</th>
                <th className="text-center">Пятница</th>
                <th className="text-center">Суббота</th>
                <th className="text-center">Воскресенье</th> */}
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user._id} className="text-center">
                  <td className="text-start">
                    <img
                      src={
                        user.avatar === "" || user.avatar === undefined
                          ? "/api/media/avatars/avatar_def.jpg"
                          : user.avatar
                      }
                      alt={user.name}
                      style={{
                        width: 70,
                        height: 70,
                        borderRadius: "50%",
                        marginBottom: 15,
                        objectFit: "cover",
                      }}
                    />{" "}
                    <p className="m-0">{user.name}</p>
                  </td>
                  {user.orders.map((day, ind) => (
                    <td style={{ minWidth: 300 }}>
                      {day.order ? (
                        <>
                          <p className="m-0 fw-light mb-2">
                            Статус:{" "}
                            {day.order.status === "ordered"
                              ? "Заказано"
                              : "Не заказано"}
                          </p>
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th width="60%">Название</th>
                                <th width="12%">Цена</th>
                                <th width="15%">К-во</th>
                              </tr>
                            </thead>
                            <tbody>
                              {day.order.meals.map((meal) => (
                                <tr ket={meal._id}>
                                  <td>{meal.name}</td>
                                  <td>{meal.price}</td>
                                  <td>{meal.amount}</td>
                                </tr>
                              ))}
                              <tr>
                                <td className="text-end" colspan="2">
                                  Итого:
                                </td>
                                <td>{day.order.total}</td>
                              </tr>
                            </tbody>
                          </Table>
                          <Button
                            variant="outline-secondary"
                            className="mt-1"
                            onClick={() => setActiveOrder(day.order._id)}
                          >
                            Подробнее
                          </Button>
                        </>
                      ) : (week[ind].denyOrderApplication &
                          week[ind].is_ordered ||
                          week[ind].denyOrderWeekend & !week[ind].is_work) &
                        !userContext.isRoot ? (
                        ""
                      ) : (
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            if (week[ind].is_ordered || !week[ind].is_work) {
                              setActiveOrderWarning({
                                reason: !week[ind].is_work
                                  ? "weekend"
                                  : week[ind].is_ordered
                                  ? "ordered"
                                  : "unknown",
                                activeOrder: {
                                  new: "new",
                                  date: day.date,
                                  user: user._id,
                                },
                              });
                            } else {
                              setActiveOrder({
                                new: "new",
                                date: day.date,
                                user: user._id,
                              });
                            }
                          }}
                        >
                          Создать заказ
                        </Button>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        ""
      )}
      <AllOrderPopup
        activeOrder={activeOrder}
        setActiveOrder={setActiveOrder}
        setNeedUpdate={setNeedUpdate}
        needUpdate={needUpdate}
      />
      <Modal
        show={!!activeOrderWarning}
        onHide={() => setActiveOrderWarning(null)}
      >
        <Modal.Header closeButton>{`${
          activeOrderWarning?.reason === "weekend"
            ? "Это выходной."
            : activeOrderWarning?.reason === "ordered"
            ? "На этот день уже создана заявка."
            : ""
        } Вы уверены, что хотите создать заказ?`}</Modal.Header>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setActiveOrderWarning(null);
              setActiveOrder(activeOrderWarning.activeOrder);
            }}
          >
            Создать заказ
          </Button>
          <Button
            onClick={() => {
              setActiveOrderWarning(null);
            }}
          >
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllOrders;
