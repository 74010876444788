import React, { useContext, useEffect, useState } from "react";
import { Table, Row, Col, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../utils/api";
import MyOrderPopup from "./MyOrderPopup";
import { UserContext } from "../utils/UserContext";

function getMonday(date) {
  const d = new Date(new Date(date).setHours(0, 0, 0, 0));
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
}

function getSunday(date) {
  const d = new Date(new Date(date).setHours(0, 0, 0, 0));
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1) + 6;
  return new Date(d.setDate(diff));
}

const weekDays = [
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
  "Воскресенье",
];

const MyOrders = () => {
  const [orders, setOrders] = useState(null);
  const [week, setWeek] = useState([]);
  const [dateFrom, setDateFrom] = useState(getMonday(new Date()));
  const [foundActiveWeek, setFoundActiveWeek] = useState(false);
  const [dateTo, setDateTo] = useState(getSunday(new Date()));
  const [ordersByWeekDays, setOrdersByWeekDays] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);
  const [activeOrderWarning, setActiveOrderWarning] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [copyLoading, setCopyLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyError, setCopyError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const navigator = useNavigate();

  const userContext = useContext(UserContext);

  useEffect(() => {
    if (!needUpdate || isFirstLoading) {
      setIsFirstLoading(false);
      return;
    }
    setIsLoading(true);
    Promise.all([
      api.getMyOrders({ f_datef: dateFrom, f_datet: dateTo, p: 1, s: 1000 }),
      api.getMyWeek({ date: dateFrom }),
    ])
      .then(([{ orders }, { result }]) => {
        setOrders(orders);
        setWeek(result);
      })
      .catch((err) => err.status === 403 && navigator("/access_denied"))
      .finally(() => {
        setNeedUpdate(false);
        setIsLoading(false);
      });
  }, [dateFrom, dateTo, needUpdate, isFirstLoading]);

  useEffect(() => {
    if (
      !week.length ||
      foundActiveWeek ||
      dateFrom.getMilliseconds() !== new Date(week[0]?.date).getMilliseconds()
    )
      return;
    if (new Date(week.filter((el) => !el.is_work)[0].date) < new Date()) {
      changeWeek("+");
    } else {
      setFoundActiveWeek(true);
    }
  }, [week]);

  useEffect(() => {
    if (!orders) return;
    const ordersByWeekDaysArray = [];
    weekDays.forEach((weekDay, index) => {
      ordersByWeekDaysArray.push({
        weekDay: weekDay,
        date: new Date(
          new Date(
            new Date(dateFrom).setDate(new Date(dateFrom).getDate() + index)
          ).setHours(0, 0, 0, 0)
        ),
        order: orders.find(
          (el) =>
            new Date(new Date(el.date).setHours(0, 0, 0, 0)).getTime() ===
            new Date(
              new Date(
                new Date(dateFrom).setDate(new Date(dateFrom).getDate() + index)
              ).setHours(0, 0, 0, 0)
            ).getTime()
        ),
      });
    });
    setOrdersByWeekDays(ordersByWeekDaysArray);
  }, [orders]);

  const changeWeek = (whereTo) => {
    if (whereTo === "+") {
      const lastWeekDay = getSunday(new Date(dateFrom));
      const firstDayNextWeek = new Date(
        new Date(lastWeekDay).setDate(lastWeekDay.getDate() + 1)
      );
      setDateFrom(getMonday(firstDayNextWeek));
      setDateTo(getSunday(firstDayNextWeek));
    } else if (whereTo === "-") {
      const lastWeekDay = getMonday(new Date(dateFrom));
      const lastDayPrevWeek = new Date(
        new Date(lastWeekDay).setDate(lastWeekDay.getDate() - 1)
      );
      setDateFrom(getMonday(lastDayPrevWeek));
      setDateTo(getSunday(lastDayPrevWeek));
    }
    setNeedUpdate(true);
  };

  const onCopy = () => {
    setCopyLoading(true);
    api
      .copyOrdersFromPrevWeek({ from: dateFrom, to: dateTo })
      .then(() => {
        setCopySuccess(true);
        setNeedUpdate(true);
      })
      .catch((err) => err.json().then(({ message }) => setCopyError(message)))
      .finally(() => setCopyLoading(false));
  };

  useEffect(() => {
    setCopySuccess(false);
    setCopyError("");
  }, [dateFrom]);

  return (
    <>
      <h1>Моя еда</h1>
      <div className="d-flex align-items-center justify-content-center">
        <i
          className="bi bi-chevron-left me-2 fs-3"
          onClick={() => changeWeek("-")}
          style={{ cursor: "pointer" }}
        />
        {dateFrom.toLocaleDateString("ru-RU", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}{" "}
        —{" "}
        {dateTo.toLocaleDateString("ru-RU", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
        <i
          className="bi bi-chevron-right fs-3 ms-2"
          onClick={() => changeWeek("+")}
          style={{ cursor: "pointer" }}
        />
      </div>
      <Button
        disabled={
          Boolean(copyLoading) || Boolean(copySuccess) || copyError !== ""
        }
        variant={copyError === "" ? "outline-primary" : "danger"}
        onClick={() => onCopy()}
        style={{ maxWidth: 300, margin: "10px auto 0" }}
      >
        {copyLoading
          ? "Копируем"
          : copySuccess
          ? "Успешно скопировали"
          : copyError !== ""
          ? copyError
          : "Скопировать с пред. недели"}
      </Button>
      <Row className="d-flex justify-content-center">
        {ordersByWeekDays
          ? ordersByWeekDays.map((day, ind) => (
              <Col
                sm={11}
                md={5}
                lg={3}
                className="card m-3 p-2"
                style={{ minHeight: 174 }}
              >
                <p
                  className={`m-0 fw-bold fs-4${
                    !week[ind].is_work ? " text-danger" : ""
                  }`}
                >
                  {day.weekDay + " "}
                  {new Date(day.date).toLocaleDateString("ru-RU", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                  {week[ind].spday ? (
                    <p className="m-0">{week[ind].spday.name}</p>
                  ) : (
                    ""
                  )}
                </p>
                {day.order ? (
                  <>
                    <p className="m-0 fw-light mb-2">
                      Статус:{" "}
                      {day.order.status === "ordered"
                        ? "Заказано"
                        : "Не заказано"}
                    </p>
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th width="60%">Название</th>
                          <th width="12%">Цена</th>
                          <th width="15%">К-во</th>
                        </tr>
                      </thead>
                      <tbody>
                        {day.order.meals.map((meal) => (
                          <tr ket={meal._id}>
                            <td>{meal.name}</td>
                            <td>{meal.price}</td>
                            <td>{meal.amount}</td>
                          </tr>
                        ))}
                        <tr>
                          <td className="text-end" colspan="2">
                            Итого:
                          </td>
                          <td>{day.order.total}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <Button
                      variant="outline-secondary"
                      className="mt-1"
                      onClick={() => setActiveOrder(day.order._id)}
                    >
                      Подробнее
                    </Button>
                  </>
                ) : (week[ind].denyOrderApplication & week[ind].is_ordered ||
                    week[ind].denyOrderWeekend & !week[ind].is_work) &
                  !userContext.isRoot ? (
                  ""
                ) : (
                  <Button
                    variant="outline-primary"
                    className="mt-4"
                    onClick={() => {
                      if (week[ind].is_ordered || !week[ind].is_work) {
                        setActiveOrderWarning({
                          reason: !week[ind].is_work
                            ? "weekend"
                            : week[ind].is_ordered
                            ? "ordered"
                            : "unknown",
                          activeOrder: { new: "new", date: day.date },
                        });
                      } else {
                        setActiveOrder({ new: "new", date: day.date });
                      }
                    }}
                  >
                    Создать заказ
                  </Button>
                )}
              </Col>
            ))
          : ""}
      </Row>
      <MyOrderPopup
        activeOrder={activeOrder}
        setActiveOrder={setActiveOrder}
        setNeedUpdate={setNeedUpdate}
        needUpdate={needUpdate}
      />
      <Modal
        show={!!activeOrderWarning}
        onHide={() => setActiveOrderWarning(null)}
      >
        <Modal.Header closeButton>{`${
          activeOrderWarning?.reason === "weekend"
            ? "Это выходной."
            : activeOrderWarning?.reason === "ordered"
            ? "На этот день уже создана заявка."
            : ""
        } Вы уверены, что хотите создать заказ?`}</Modal.Header>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setActiveOrderWarning(null);
              setActiveOrder(activeOrderWarning.activeOrder);
            }}
          >
            Создать заказ
          </Button>
          <Button
            onClick={() => {
              setActiveOrderWarning(null);
            }}
          >
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyOrders;
