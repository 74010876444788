import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { api } from "../../../utils/api";
import TeamWeekends from "./TeamWeekends";

export default function Team() {
  const [form, setForm] = useState({
    name: "",
    menuInclude: {
      mealty: false,
    },
    useExport: {
      mealty: false,
    },
    denyOrderApplication: false,
    denyOrderWeekend: false,
    defaultWorkingDays: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false,
    },
  });
  const [isSPDaysOpen, setIsSPDaysOpen] = useState(false);

  useEffect(() => {
    api.getOurTeam().then(({ team }) => {
      setForm(team);
    });
  }, []);

  const onEditTeam = (e) => {
    e.preventDefault();
    const {
      name,
      menuInclude,
      useExport,
      denyOrderWeekend,
      denyOrderApplication,
      defaultWorkingDays,
    } = form;
    api
      .editOurTeam({
        name,
        menuInclude,
        useExport,
        denyOrderWeekend,
        denyOrderApplication,
        defaultWorkingDays,
      })
      .then();
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onChangeBool = (e) => {
    setForm({
      ...form,
      [e.target.name]: !form[e.target.name],
    });
  };

  const onChangeSwitchExport = (e) => {
    setForm({
      ...form,
      useExport: {
        ...form.useExport,
        [e.target.name]: !form.useExport[e.target.name],
      },
    });
  };

  const onChangeSwitchMenu = (e) => {
    setForm({
      ...form,
      menuInclude: {
        ...form.menuInclude,
        [e.target.name]: !form.menuInclude[e.target.name],
      },
    });
  };

  const onChangeWorkingDays = (e) => {
    setForm({
      ...form,
      defaultWorkingDays: {
        ...form.defaultWorkingDays,
        [e.target.name]: !form.defaultWorkingDays[e.target.name],
      },
    });
  };

  return (
    <Card className="p-3">
      <Form onSubmit={(e) => onEditTeam(e)}>
        <Form.Group className="mb-3">
          <Form.Label>Название команды</Form.Label>
          <Form.Control
            type="text"
            placeholder="Введите название"
            value={form.name}
            onChange={onChange}
            name="name"
          />
        </Form.Group>
        <p className="mb-1 mt-3">Интеграция меню</p>
        <Form.Check
          type="switch"
          className="mt-2 mb-2"
          label=" Милти"
          name="mealty"
          checked={form.menuInclude.mealty === true ? "on" : ""}
          onChange={(e) => onChangeSwitchMenu(e)}
        />
        <p className="mb-1 mt-3">Интеграция заявок</p>
        <Form.Check
          type="switch"
          className="mt-2 mb-2"
          label=" Милти"
          name="mealty"
          checked={form.useExport.mealty === true ? "on" : ""}
          onChange={(e) => onChangeSwitchExport(e)}
        />
        <p className="mb-1 mt-3">Запретить заказывать на выходные</p>
        <Form.Check
          type="switch"
          className="mt-2 mb-2"
          name="denyOrderWeekend"
          checked={form.denyOrderWeekend === true ? "on" : ""}
          onChange={(e) => onChangeBool(e)}
        />
        <p className="mb-1 mt-3">
          Запретить заказывать на дни, когда уже есть заявка
        </p>
        <Form.Check
          type="switch"
          className="mt-2 mb-2"
          name="denyOrderApplication"
          checked={form.denyOrderApplication === true ? "on" : ""}
          onChange={(e) => onChangeBool(e)}
        />
        <p className="mb-1 mt-3">Рабочие дни по умолчанию</p>
        {[
          { value: "monday", title: "Понедельник" },
          { value: "tuesday", title: "Вторник" },
          { value: "wednesday", title: "Среда" },
          { value: "thursday", title: "Четверг" },
          { value: "friday", title: "Пятница" },
          { value: "saturday", title: "Суббота" },
          { value: "sunday", title: "Воскресенье" },
        ].map(({ value, title }, index) => (
          <Form.Check
            key={index}
            type="switch"
            label={" " + title}
            className="mt-2 mb-2"
            name={value}
            checked={form.defaultWorkingDays[value] === true ? "on" : ""}
            onChange={(e) => onChangeWorkingDays(e)}
          />
        ))}
        <Button className="mt-3" onClick={() => setIsSPDaysOpen(true)}>
          Выходные и праздничные дни
        </Button>
        <Card.Footer className="mt-3 d-flex justify-content-end">
          <Button variant="primary" type="submit">
            Сохранить
          </Button>
        </Card.Footer>
      </Form>
      <TeamWeekends
        isSPDaysOpen={isSPDaysOpen}
        setIsSPDaysOpen={setIsSPDaysOpen}
      />
    </Card>
  );
}
