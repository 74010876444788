import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { api } from "../../../utils/api";
import Weekend from "./Weekend";

const years = [];

for (let i = 2024; i <= new Date().getFullYear() + 1; i++) {
  years.push(i);
}

export default function TeamWeekends({ isSPDaysOpen, setIsSPDaysOpen }) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [spdays, setSpdays] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [spdayId, setSpdayId] = useState(null);
  useEffect(() => {
    api
      .getSPDays({
        f_datef: new Date(year, 0, 1),
        f_datet: new Date(year, 11, 31),
        s: 10000,
        p: 1,
        sort_by: 'date',
        sort_dir: 1,
      })
      .then(({ spdays }) => setSpdays(spdays))
      .catch((err) => console.log(err));
  }, [year, isEditModalOpen, isSPDaysOpen]);
  return (
    <>
      <Modal
        show={isSPDaysOpen}
        onHide={() => setIsSPDaysOpen(false)}
        size="lg"
      >
        <Modal.Header closeButton>Выходные и праздничные дни</Modal.Header>
        <Modal.Body>
          <p className="mb-1 mt-3">Год</p>
          <Form.Select value={year} onChange={(e) => setYear(e.target.value)}>
            {years.map((el, ind) => (
              <option value={el} key={ind}>
                {el}
              </option>
            ))}
          </Form.Select>
          <Table striped bordered hover size="sm" className="mt-3">
            <thead>
              <tr>
                <th width="30%">Дата</th>
                <th width="50%">Название</th>
                <th width="20%">Тип</th>
              </tr>
            </thead>
            <tbody>
              {spdays.map(({ _id, date, name, status }) => (
                <tr
                  key={_id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsEditModalOpen(true);
                    setSpdayId(_id);
                  }}
                >
                  <td>
                    {new Date(date).toLocaleDateString("ru-RU", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </td>
                  <td>{name}</td>
                  <td>{status === "weekend" ? "Выходной" : "Рабочий день"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button onClick={() => setIsEditModalOpen(true)}>
            Добавить день
          </Button>
        </Modal.Body>
      </Modal>
      <Weekend
        setIsEditModalOpen={setIsEditModalOpen}
        isEditModalOpen={isEditModalOpen}
        spdayId={spdayId}
        setSpdayId={setSpdayId}
      />
    </>
  );
}
